export const pDFPageNumber = (currentPage, pageCount, style) => {
    const text = 'Page ' + currentPage.toString() + ' of ' + pageCount
    return writeTextForPDF(text, { alignment: 'right', margin: [0, 0, 10, 0], ...style })
    return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 0, 10, 0] }
}

export const styleText = (text, { bold, italics, alignment, font, fontSize, margin, color, decoration, underline, fillColor }) => {
    let obj = { text }
    if (bold) obj.bold = bold
    if (italics) obj.italics = italics
    if (alignment) obj.alignment = alignment
    if (font) obj.font = font
    if (fontSize) obj.fontSize = fontSize
    if (margin) obj.margin = margin
    if (color) obj.color = color
    if (decoration) obj.decoration = decoration
    if (fillColor) obj.fillColor = fillColor
    if (underline) {
        return {
            table: {
                body: [[{ ...obj }]]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0) ? 0 : 1;
                },
                vLineWidth: function (i) {
                    return 0
                }
            }
        }
    }

    return obj
}

//Regex will validate text only contains english and special characters
const regexPateren = /^[A-Za-z0-9 !@#$%^&*()_+\-{}[\]';":,.\/<>,?|\n\r]+$/


//in future can implement by character if required

const proceessByWord = (text = '', style) => {
    try {
        const words = text.split(' ')
        const length = words.length
        const arr = words.map((word, index) => {
            const tempStyleTxt = (sty = style) => styleText(word + ((index < (length - 1)) ? ' ' : ''), sty)
            if (!word)
                return { text: word }
            if (!isNaN(Number(word)))
                return tempStyleTxt()
            if (regexPateren.test(word))
                return tempStyleTxt()
            return tempStyleTxt({ ...style, font: 'Uni' })
        })
        return { text: arr }
    } catch (error) {
        console.error('problem with pdf font | in word ', { text, style, error });
        return { text, style }
    }
}

const processBySentence = (text = '', style) => {
    try {
        const sentences = text.split('\n')
        const length = sentences.length
        const arr = sentences.map((sentence, index) => {
            const newsentence = sentence + ((index < (length - 1)) ? '\n' : '')
            const tempStyleTxt = (sty = style) => styleText(newsentence, sty)
            if (!sentence)
                return { text: sentence }
            if (!isNaN(Number(sentence)))
                return tempStyleTxt()
            if (regexPateren.test(sentence)) {
                return tempStyleTxt()
            }
            if (sentence.includes(' '))
                return proceessByWord(newsentence, style)
            return tempStyleTxt({ font: 'Uni', ...style })
        })
        return { text: arr }

    } catch (error) {
        console.error('problem with pdf font | in sentence ', { text, style, error });
        return { text, style }
    }
}

export const writeTextForPDF = (text = '', sty = {}) => {
    const { underline, ...style } = sty
    try {
        if (!text)
            return { text }
        if (!isNaN(Number(text)))
            return styleText(text, sty)
        if (regexPateren.test(text)) {
            // if (regexPateren.test(text.replace('\n', ''))) {
            return styleText(text, sty)
        }
        if (text.includes(' ') || text.includes('\n'))
            return processBySentence(text, style)
        return styleText(text, { font: 'Uni', ...style })
    } catch (error) {
        console.error('problem with pdf font | in write pdf text', { error, text, style });
        return { text, style }
    }
}


export const getBase64ImageFromURL = async (url = '') => {
    if (!url) return ''
    return await new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    }).then(dataurl => dataurl)
        .catch(err => {
            console.error('image url to dataurl got failed', err);
            return ''
        })
}


export function splitIntoLines(sentence = '', charactersPerLine = 22) {
    const lines = [];

    // Split the input paragraph into statements based on newlines
    const statements = sentence.split('\n');

    statements.forEach((statement) => {
        const words = statement.split(' ');
        let currentLine = '';

        for (let i = 0; i < words.length; i++) {
            let word = words[i];

            if (word.length > charactersPerLine) {
                // If the word itself is longer than the line length, break it into multiple lines
                while (word.length > charactersPerLine) {
                    lines.push(word.substring(0, charactersPerLine));
                    word = word.substring(charactersPerLine);
                }
            }

            if ((currentLine + word).length <= charactersPerLine) {
                // If adding the word doesn't exceed the character limit, add it to the current line
                currentLine += (currentLine === '' ? '' : ' ') + word;
            } else {
                // If adding the word exceeds the character limit, start a new line
                lines.push(currentLine);
                currentLine = word;
            }
        }

        // If there are remaining words, add them as the last line
        if (currentLine !== '') {
            lines.push(currentLine);
        }
    });

    return lines;
}

// Example usage
// const paragraph = "This is the first statement of the paragraph with some text. \n\nThis is the second statement, and it includes a newline between sentences. \n\nThe third statement is here.";
// const charactersPerLine = 30;

// const lines = splitIntoLines(paragraph, charactersPerLine);

// console.log("The paragraph is split into lines:", lines);
// lines.forEach((line, index) => {
//     console.log(`Line ${index + 1}: ${line}`);
// });

const sampleSize = {
    10: {
        single: 15,
        first: 33,
        mid: 33,
        last: 12,
        full: 45,
    },
}

const sampleColumns = [
    'sno',
    'name',
]

export const planPDFPages = (items = [], { columns, renderItemFunc, }) => {

}
